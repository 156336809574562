import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import firebase from "../../../firebase";

const configHeader = {
  headers: { Authorization: `Bearer ${process.env.REACT_APP_KEY_TOKEN}` },
};

const TemperatureCharts = ({ dataToChart }) => {
  const {
    matriculaTransportista,
    dataEntrega,
    dataAlta,
    aliesEnviament,
    idAlbara,
  } = dataToChart;
  const dateDelivery = dataEntrega.replace(/-/g, "");
  const orderDate = dataAlta.replace(/-/g, "");
  // Date when it starts to get data (13/03/2021)
  const initialDateData = new Date("2021-03-12").getTime();
  var tempTodayVehicleExpedition = [];
  var tempYesterdayVehicleExpedition = [];
  var [vehicleExpeditionTimestamp, setVehicleExpeditionTimestamp] = useState(
    ""
  );
  var [licensePlateExpedition, setLicensePlateExpedition] = useState("");
  var [licensesPlatesVehicles, setLicensesPlatesVehicles] = useState([]);
  var [vehiclesChangeTimestamp, setVehiclesChangeTimestamp] = useState([]);

  var [thereIsData, setThereIsData] = useState(true);
  const [moreThanOneVehicle, setMoreThanOneVehicle] = useState(false);

  const [
    tempTodayExpeditonVehicleChart,
    setTempTodayExpeditionVehicleChart,
  ] = useState([{}]);
  const [
    tempYesterdayExpeditonVehicleChart,
    setTempYesterdayExpeditionVehicleChart,
  ] = useState([{}]);
  var [tempTodayVehiclesChart, setTempTodayVehiclesChart] = useState([]);
  var [tempYesterdayVehiclesChart, setTempYesterdayVehiclesChart] = useState(
    []
  );

  useEffect(() => {
    const fetchTemperatures = () => {
      axios
        .get(
          `https://258.stratya.com/apiV2/clientsAlbarans/obtenirCanviEstatExpedicioAlbara?id=${idAlbara}`,
          configHeader
        )
        .then((response) => {
          licensesPlatesVehicles = [];
          vehiclesChangeTimestamp = [];
          for (let index in response.data) {
            let expeditionState = response.data[index];
            // Get license plate of the expedition vehicle
            if (
              expeditionState.estatOrigen == 1 &&
              expeditionState.estatDesti == 2
            ) {
              // Check if we can display data (no before than 10/03/2021)
              let expeditionDate = expeditionState.dtaActualitzacio;
              if (expeditionDate < initialDateData) {
                setThereIsData(false);
                break;
              } else {
                setThereIsData(true);
              }

              licensePlateExpedition = expeditionState.matricula;
              setLicensePlateExpedition(licensePlateExpedition);
              console.log(expeditionDate);
              let expeditionTimestampDate = new Date(
                expeditionDate
              ).toLocaleString("es-ES");
              console.log(expeditionTimestampDate);
              setVehicleExpeditionTimestamp(expeditionTimestampDate);
            }
            // Get other license plane in cas there is more changes
            if (
              expeditionState.estatOrigen == 4 &&
              expeditionState.estatDesti == 2
            ) {
              licensesPlatesVehicles.push(expeditionState.matricula);
              setLicensesPlatesVehicles(licensesPlatesVehicles);
              vehiclesChangeTimestamp.push(
                new Date(expeditionState.dtaActualitzacio).toLocaleString(
                  "es-ES"
                )
              );
              setVehiclesChangeTimestamp(vehiclesChangeTimestamp);
            }
          }

          if (licensesPlatesVehicles.length < 1) {
            setMoreThanOneVehicle(false);
          } else {
            setMoreThanOneVehicle(true);
          }

          // console.log(licensePlateExpedition);
          // console.log(licensesPlatesVehicles);
          // console.log(vehiclesChangeTimestamp);

          if (thereIsData) {
            // Get expedition vehicle temperature data - today
            firebase
              .firestore()
              .collection("vehicles")
              .doc(licensePlateExpedition)
              .collection("temperatures")
              .doc(dateDelivery)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  tempTodayVehicleExpedition = doc.data().temperatures;
                  setTempTodayExpeditionVehicleChart(
                    tempTodayVehicleExpedition
                  );
                }
              });

            // Get expedition vehicle temperature data - yesterday
            firebase
              .firestore()
              .collection("vehicles")
              .doc(licensePlateExpedition)
              .collection("temperatures")
              .doc(orderDate)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  tempYesterdayVehicleExpedition = doc.data().temperatures;
                  setTempYesterdayExpeditionVehicleChart(
                    tempYesterdayVehicleExpedition
                  );
                }
              });

            // Get temperature data vehicles
            if (licensesPlatesVehicles.length > 0) {
              tempTodayVehiclesChart = [];
              tempYesterdayVehiclesChart = [];
              for (let index in licensesPlatesVehicles) {
                let licensePlateVehicle = licensesPlatesVehicles[index];
                console.log(dateDelivery);
                // Today
                firebase
                  .firestore()
                  .collection("vehicles")
                  .doc(licensePlateVehicle)
                  .collection("temperatures")
                  .doc(dateDelivery)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      tempTodayVehiclesChart.push(doc.data().temperatures);
                      setTempTodayVehiclesChart(tempTodayVehiclesChart);
                    } else {
                      // console.log("doc not exists");
                    }
                  });

                // Yesterday
                firebase
                  .firestore()
                  .collection("vehicles")
                  .doc(licensePlateVehicle)
                  .collection("temperatures")
                  .doc(orderDate)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      tempYesterdayVehiclesChart.push(doc.data().temperatures);
                      setTempYesterdayVehiclesChart(tempYesterdayVehiclesChart);
                    } else {
                      // console.log("doc not exists");
                    }
                  });
              }
            }
          }
        })
        .catch((error) => console.log(error));
    };
    fetchTemperatures();
  }, [dataToChart]);

  return (
    <>
      <div>
        {!thereIsData ? (
          <div className="flex flex-wrap">
            <h1 className="mr-5">
              No hay datos disponibles anteriores al 13/03/2021. Escoja una
              fecha igual o posterior.
            </h1>
          </div>
        ) : (
          <>
            <div className="flex flex-wrap">
              <h1 className="mr-5">Hora inicio expedición: </h1>
              <h1 className="font-bold">{vehicleExpeditionTimestamp}</h1>
            </div>
            <div className="flex flex-wrap">
              {!tempYesterdayExpeditonVehicleChart.length ? (
                <h1>No hay datos disponibles de ayer</h1>
              ) : (
                <AreaChart
                  width={600}
                  height={400}
                  data={tempYesterdayExpeditonVehicleChart}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dataInfo" />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Area
                    name={licensePlateExpedition}
                    type="monotone"
                    dataKey="temperature"
                    stroke="#802640"
                    fill="#802640"
                  />
                </AreaChart>
              )}
              {!tempTodayExpeditonVehicleChart.length ? (
                <h1 className="ml-5">No hay datos disponibles de hoy</h1>
              ) : (
                <AreaChart
                  width={600}
                  height={400}
                  data={tempTodayExpeditonVehicleChart}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dataInfo" />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Area
                    name={licensePlateExpedition}
                    type="monotone"
                    dataKey="temperature"
                    stroke="#802640"
                    fill="#802640"
                  />
                </AreaChart>
              )}
            </div>
            <div className="flex flex-wrap">
              {!moreThanOneVehicle ? (
                <h1 className="font-bold">No ha habido cambio de vehículos</h1>
              ) : (
                <ul>
                  {licensesPlatesVehicles.map((licensePlate, index) => {
                    return (
                      <>
                        <div className="flex flex-wrap">
                          <h1 className="mr-5">Hora de cambio de vehículo:</h1>
                          <h1 className="font-bold">
                            {vehiclesChangeTimestamp[index]}
                          </h1>
                        </div>

                        <div className="flex flex-wrap">
                          <AreaChart
                            width={600}
                            height={400}
                            data={tempYesterdayVehiclesChart[index]}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dataInfo" />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} />
                            <Area
                              name={licensePlate}
                              type="monotone"
                              dataKey="temperature"
                              stroke="#802640"
                              fill="#802640"
                            />
                          </AreaChart>

                          <AreaChart
                            width={600}
                            height={400}
                            data={tempTodayVehiclesChart[index]}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dataInfo" />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" height={36} />
                            <Area
                              name={licensePlate}
                              type="monotone"
                              dataKey="temperature"
                              stroke="#802640"
                              fill="#802640"
                            />
                          </AreaChart>
                        </div>
                      </>
                    );
                  })}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TemperatureCharts;
