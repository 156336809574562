import axios from "axios";
import es from "date-fns/locale/es";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "./footer";
import Navbar from "./navbar";
import OrderTable from "./OrderTable";
import TemperatureCharts from "./TemperatureCharts";
registerLocale("es", es);

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const configHeader = {
  headers: { Authorization: `Bearer ${process.env.REACT_APP_KEY_TOKEN}` },
};

const dataToChartInitial = {
  licensePlate: "-",
  deliveryDate: "-",
  orderDate: "-",
};

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [start, setStart] = useState(formatDate(new Date()));
  const [end, setEnd] = useState(formatDate(new Date()));
  const [dataToChart, setDataToChart] = useState(dataToChartInitial);
  const [data, setData] = useState({
    albarans: [
      {
        referenciaClient: "",
        dataAlta: "",
        dataEntrega: "",
        aliesEnviament: "",
        estatExpedicio: "",
      },
    ],
  });
  // var orders = [];
  var dataReduced = [];
  // var start;
  // var end;

  useEffect(() => {
    const fetchData = () => {
      let orders = [];
      axios
        .get(
          `https://258.stratya.com/apiV2/clientsAlbarans?filter=codiClient:000401&lite=1&dtaEntregaInterval=from:${start},to:${end}`,
          configHeader
        )
        .then((response) => {
          // console.log(response.data);
          if ((response.data === undefined) | (response.data.length == 0)) {
            // console.warn("EMPTY ARRAY!!");
            dataReduced = [
              {
                referenciaClient: "",
                dataAlta: "",
                dataEntrega: "",
                aliesEnviament: "",
                estatExpedicio: "",
              },
            ];
            orders = orders.concat(dataReduced);
            // setData(dataReduced);
          } else {
            orders = orders.concat(response.data);
            // console.log(response.data);
            // setData(response.data);
          }
        })
        .then(
          axios
            .get(
              `https://258.stratya.com/apiV2/clientsAlbarans?filter=codiClient:000402&lite=1&dtaEntregaInterval=from:${start},to:${end}`,
              configHeader
            )
            .then((response) => {
              if ((response.data === undefined) | (response.data.length == 0)) {
                dataReduced = [
                  {
                    referenciaClient: "",
                    dataAlta: "",
                    dataEntrega: "",
                    aliesEnviament: "",
                    estatExpedicio: "",
                  },
                ];
                orders = orders.concat(dataReduced);
                setData(orders);
              } else {
                let ordersClient2 = response.data;
                let ordersClients = orders.concat(ordersClient2);
                setData(ordersClients);
              }
            })
        )
        .catch((error) => {
          console.log("error fetching: " + error);
        });
    };

    fetchData();
  }, [start, end]);

  const handleDateChange = (dates) => {
    var [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end === null) {
      end = start;
    }
    setStart(formatDate(start));
    setEnd(formatDate(end));
    // console.log("Start date: " + start);
    // console.log("End date: " + end);
  };

  const handleRowClick = (order) => {
    // console.log(order);
    const {
      idAlbara,
      matriculaTransportista,
      dataEntrega,
      dataAlta,
      aliesEnviament,
    } = order;
    setDataToChart({
      idAlbara,
      matriculaTransportista,
      dataEntrega,
      dataAlta,
      aliesEnviament,
    });
    // console.log(dataToChart);
  };

  const CustomInputDatePicker = ({ value, onClick }) => (
    <button className="border-b-2 border-red-900 bg-gray-200" onClick={onClick}>
      {value}
    </button>
  );

  return (
    <div className="flex flex-col h-screen justify-between">
      <header>
        <Navbar />
      </header>
      <main className="container mx-auto p-4 flex-row items-center md:flex-no-wrap mb-auto">
        <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Control de temperaturas
        </h1>
        {/* <div className="grid grid-cols-2 gap-4 mt-8"> */}

        <div className="mt-8">
          <div className="mb-4 flex">
            <h2 className="mb-4 mr-4">Fechas pedidos:</h2>
            <DatePicker
              locale="es"
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInputDatePicker />}
            />
          </div>
          {!data.length ? (
            <div>Loading...</div>
          ) : (
            <OrderTable albarans={data} handleRowClick={handleRowClick} />
          )}

          <div className="text-xl leading-7 text-gray-800 sm:text-2xl sm:truncate mt-6">
            Histórico temperaturas
          </div>
          <div className="mt-4">
            {dataToChart.deliveryDate !== undefined ||
            dataToChart.licensePlate !== undefined ||
            dataToChart.orderDate !== undefined ? (
              <div>No hay pedido seleccionado</div>
            ) : (
              <TemperatureCharts dataToChart={dataToChart} />
            )}
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Dashboard;
