import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 mt-16">
      <div className="container mx-auto border-none px-4">
        <section className="flex flex-col md:flex-row md:justify-between text-white text-sm pt-4 pb-6 md:pt-5 md:pb-6 w-full">
          <div>
            <p className="leading-8 tracking-wide">
              Sergal Gestió Ramadera, S.L.
            </p>
          </div>
          <div>
            <p className="leading-8 tracking-wide">inSergal &copy;</p>
          </div>
        </section>
      </div>
    </footer>
    // <script>
    //   function toggleFooterSection(e) {
    //     const list = e.target.parentElement.parentElement.querySelector(
    //       "article"
    //     );
    //     if (list.classList.contains("h-0")) {
    //       list.classList.remove("h-0");
    //     } else {
    //       list.classList.add("h-0");
    //     }
    //   }
    // </script>
  );
};

export default Footer;
