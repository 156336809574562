import React, { useMemo } from "react";
import { useTable } from "react-table";

const OrderTable = ({ albarans, handleRowClick }) => {
  const data = useMemo(() => albarans, [albarans]);
  const columns = useMemo(
    () => [
      {
        Header: "Pedido",
        accessor: "referenciaClient",
      },
      {
        Header: "Fecha de alta",
        accessor: "dataAlta",
      },
      {
        Header: "Fecha de entrega",
        accessor: "dataEntrega",
      },
      {
        Header: "Dirección Entrega",
        accessor: "aliesEnviament",
      },
      // {
      //   Header: "Estado",
      //   accessor: "estatExpedicio",
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mr-5">
      <table
        className="min-w-full divide-y divide-gray-200"
        {...getTableProps()}
      >
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="bg-white divide-y divide-gray-200"
          {...getTableBodyProps()}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="cursor-pointer hover:bg-gray-200"
                onClick={() => handleRowClick(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="px-6 py-4 whitespace-nowrap"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
