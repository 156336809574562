import "firebase/auth";
import "firebase/firestore";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import logoinsergal from "../../../assets/inSergal-72dpi.png";
import labinsergalphoto from "../../../assets/lab-insergal.jpg";
import { AuthContext } from "../../../AuthProvider";
import firebase from "../../../firebase";

interface ILogInFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email().required("Correo requerido"),
  password: Yup.string().required("Contraseña requerida"),
});

const Login = () => {
  const authContext = useContext(AuthContext);
  const { loadingAuthState } = useContext(AuthContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
  } as ILogInFormValues);

  const db = firebase.firestore();

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (!result || !result.user || !firebase.auth().currentUser) {
          return;
        }

        return setUserProfile().then(() => {
          redirectToTargetPage();
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  const setUserProfile = async () => {
    if (await isUserExists()) {
      return;
    }

    const currentUser = firebase.auth().currentUser!;
    db.collection("users")
      .doc(currentUser.uid)
      .set({
        username: currentUser.displayName,
      })
      .then(() => {
        // console.log("Saved");
        return;
      })
      .catch((error) => {
        console.log(error.message);
        alert(error.message);
      });
  };

  const isUserExists = async () => {
    const doc = await db
      .collection("users")
      .doc(firebase.auth().currentUser!.uid)
      .get();
    return doc.exists;
  };

  const redirectToTargetPage = () => {
    history.push("/dashboard");
  };

  const handleSubmit = (
    values: ILogInFormValues,
    { setSubmitting }: FormikHelpers<ILogInFormValues>
  ) => {
    // event.preventDefault();
    setSubmitting(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((res) => {
        authContext.setUser(res);
        setSubmitting(false);
        // console.log(res, "res");
        history.push("/dashboard");
      })
      .catch((error) => {
        console.log(error.message);
        alert(error.message);
      });
  };

  return (
    <div className="bg-white font-family-lato h-screen">
      <div className="w-full flex flex-wrap">
        <div className="w-full md:w-1/3 flex flex-col">
          <div className="flex justify-center md:justify-start pt-12 md:pl-12 md:-mb-24">
            <input type="image" src={logoinsergal} className="w-40" alt="" />
          </div>
          <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
            <p className="text-center text-3xl">Control de temperaturas</p>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <Form className="flex flex-col pt-3 md:pt-8">
                <div className="flex flex-col pt-4">
                  <label htmlFor="email" className="text-lg">
                    Correo electrónico
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="test@test.com"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="flex flex-col pt-4">
                  <label htmlFor="password" className="text-lg">
                    Contraseña
                  </label>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Contraseña"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <input
                  type="submit"
                  value={loadingAuthState ? "Loading..." : "Entrar"}
                  className="bg-red-800 text-white font-bold text-lg hover:bg-red-900 p-2 mt-8 cursor-pointer rounded"
                />
              </Form>
            </Formik>
          </div>
        </div>
        <div className="w-2/3 shadow-2xl">
          <img
            className="object-cover w-full h-screen hidden md:block"
            src={labinsergalphoto}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
