import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoutes';
import { AuthRoutes } from '../views/Auth';
import { DashboardRoutes } from '../views/Dashboard';

const ApplicationRoutes = () => {
    return (
        <Router>
            <Switch>
                <PrivateRoute exact path='/dashboard' component={DashboardRoutes} />
                <Route path='/auth' component={AuthRoutes} />
                <Redirect to='/auth' from='/' />
            </Switch>
        </Router>
    );
}

export default ApplicationRoutes;